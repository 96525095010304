<template>
  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <c-table
        ref="table"
        title="관련 가동전점검 개선사항 목록"
        :columns="grid.columns"
        :gridHeight="gridheight"
        :data="moc.prestartupImproves"
        :merge="grid.merge"
        :filtering="false"
        :columnSetting="false"
        :usePaging="false"
        :editable="editable&&!disabled"
        @linkClick="linkClick"
      >
        <template v-slot:customArea="{ props, col }">
          <template v-if="col.name==='customCol'">
            <component
              :is="imprComponent"
              :col="col"
              :props="props"
              :inputEditable="false"
              :isImmShow="true"
              ibmTaskTypeCd="ITT0000025"
              ibmTaskUnderTypeCd="ITTU000040"
              @imprChange="imprChange"
            />
          </template>
        </template>
      </c-table>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <c-table
        ref="gridImpr"
        title="항목외 개선 목록"
        :columns="gridImpr.columns"
        :data="gridImpr.data"
        :gridHeight="gridheight2"
        :usePaging="false"
        :filtering="false"
        :columnSetting="false"
        @linkClick="linkClick2"
      >
        <template slot="table-button">
          <q-btn-group outline >
            <!-- 개선요청 -->
            <c-btn v-if="editable && !disabled && moc.sopMocId" label="개선요청" :showLoading="false"  icon="add" @btnClicked="addImpr" />
            <!-- 즉시조치 -->
            <c-btn v-if="editable && !disabled && moc.sopMocId" label="즉시조치" :showLoading="false"  icon="add" @btnClicked="addiimImpr" />
          </q-btn-group>
        </template>
      </c-table>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'moc-pre-startup-impr',
  props: {
    moc: {
      type: Object,
      default: () => ({
        sopMocId: '',  // MOC 일련번호
        mocTypeCd: 'MT00000001',  // 변경구분_공통코드(정상/비상/임시)
        mocStepCd: '',  // 변경관리 단계
        prestartupImproves: [],
      }),
    },
    param: {
      type: Object,
      default: () => ({
        sopMocId: '',  // MOC 일련번호
      }),
    },
    disabled: {
      type: Boolean,
      default: function() {
        return false;
      },
    },
    height: {
      type: String,
      default: function() {
        return '700px';
      },
    },
  },
  data() {
    return {
      grid: {
        merge: [
          { index: 0, colName: 'checkName' },
          { index: 1, colName: 'equipmentName' },
          { index: 2, colName: 'mergeCheck1' },
          { index: 3, colName: 'mergeCheck2' },
          { index: 4, colName: 'mergeCheck2' },
          { index: 5, colName: 'mergeCheck2' },
        ],
        columns: [
          {
            name: 'checkName', 
            field: 'checkName',
            label: '가동전점검명',
            align: 'center',
            style: 'width:200px',
            sortable: false,
            type: 'link'
          },
          {
            name: 'equipmentName', 
            field: 'equipmentName',
            label: '설비',
            align: 'center',
            style: 'width:120px',
            sortable: false,
            type: 'link'
          },
          {
            name: 'psrCheckTypeName', 
            field: 'psrCheckTypeName',
            label: '점검유형',
            align: 'center',
            style: 'width:130px',
            sortable: false,
          },
          {
            name: 'checkItemName',
            field: 'checkItemName',
            label: '점검항목',
            sortable: false,
            child: [
              {
                name: 'level',
                field: 'level',
                label: '대분류/중분류/소분류',
                align: 'left',
                // style: 'width:850px',
                sortable: false,
              },
            ]
          },
          {
            name: 'psrCheckPeriodName',
            field: 'psrCheckPeriodName',
            label: '점검시기',
            align: 'center',
            style: 'width:100px',
            sortable: false,
          },
          {
            name: 'judgmentResultFlagName',
            field: 'judgmentResultFlagName',
            label: '적합여부',
            align: 'center',
            style: 'width:50px',
            sortable: false,
          },
          {
            name: 'customCol',
            field: 'customCol',
            label: '개선/진행상태',
            align: 'center',
            type: 'custom',
            style: 'width:200px',
            sortable: false
          }, 
        ],
        data: [],
        // height: '475px'
      },
      gridImpr: {
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            label: '사업장',
            align: 'center',
            style: 'width:100px',
            sortable: false,
          },
          {
            name: 'ibmClassName',
            field: 'ibmClassName',
            label: '구분',
            align: 'center',
            style: 'width:80px',
            sortable: false,
          },
          {
            name: 'actionDeptName',
            field: 'actionDeptName',
            label: '조치부서',
            align: 'center',
            style: 'width:120px',
            sortable: false,
          },
          {
            name: 'ibmTitle',
            field: 'ibmTitle',
            label: '제목',
            align: 'left',
            style: 'width:300px',
            sortable: false,
            type: 'link',
          },
          {
            name: 'actionContents',
            field: 'actionContents',
            label: '조치내용',
            align: 'left',
            style: 'width:400px',
            sortable: false,
          },
          {
            name: 'actionCompleteDate',
            field: 'actionCompleteDate',
            label: '조치완료일',
            align: 'center',
            style: 'width:120px',
            sortable: false,
          },
        ],
        data: [],
      },
      editable: true,
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '80%',
        param: {},
        closeCallback: null,
      },
      getUrl: '',
      impListUrl: '',
      requestImprRow: null,
      imprData: null,
      colorItems: [],
      gridheight: '',
      gridheight2: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    setGridHeight() {
      let margin = 80;
      let _height = this.height ? Number(this.height.replace('px', '')) : 700;
      return (_height + margin) + 'px'
    },
    // 개선요청 Component
    imprComponent() {
      let components = () => import(`${'@/pages/common/ibm/tableImpr.vue'}`);
      return components;
    },
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      this.gridheight2 = (window.innerHeight - 608) + 'px';
      this.gridheight = '400px';
      // url setting
      this.impListUrl = selectConfig.sop.ibm.improve.others.url;
      // this.$comm.getStepItems('IBM_STEP_CD').then(_result => {
      //   this.colorItems = _result
      // });
      // code setting
      // list setting
      this.getImpList();
    },
    getImpList() {
      if (this.param.sopMocId) {
        this.$http.url = this.impListUrl;
        this.$http.param = {
          ibmTaskTypeCd: 'ITT0000025', 
          ibmTaskUnderTypeCd: 'ITTU000040', 
          relationTableKey: this.param.sopMocId
        };
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.gridImpr.data = _result.data;
        },);
      }
    },
    imprChange() {
      this.$emit('imprChange')
    },
    /* eslint-disable no-unused-vars */
    linkClick(row, col, data, num) {
      if (col.name === 'customCol' || col.name === 'ibmTitle') {
        this.requestImprRow = row
        this.imprData = data;
        if (row.ibmClassCd === 'IC00000001') {
          this.popupOptions.title = '개선';
          this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprDetail.vue'}`);
        } else {
          this.popupOptions.title = '즉시조치';
          this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprImmDetail.vue'}`);
        }
        this.popupOptions.param = {
          sopImprovementId: row.sopImprovementId,
        };
        this.popupOptions.width = '80%';
        this.popupOptions.visible = true;
        if (num == '2') {
          this.popupOptions.closeCallback = this.closeImprPopup2;
        } else {
          this.popupOptions.closeCallback = this.closeImprPopup;
        }
      } else if (col.name === 'equipmentName') {
        this.popupOptions.title = '설비 상세'; 
        this.popupOptions.param = row;
        this.popupOptions.target = () => import(`${'@/pages/mdm/fim/equipmentDetail.vue'}`);
        this.popupOptions.width = '90%';
        this.popupOptions.visible = true;
        this.popupOptions.closeCallback = this.closeFacilityPopup;
      } else {
        this.popupOptions.title = '가동전점검 상세'; 
        this.popupOptions.param = {
          sopPrestartupCheckId: row ? row.sopPrestartupCheckId : '',
        };
        this.popupOptions.target = () => import(`${'@/pages/sop/psr/preStartupReviewDetail.vue'}`);
        this.popupOptions.width = '90%';
        this.popupOptions.visible = true;
        this.popupOptions.closeCallback = this.closeFacilityPopup;
      }
    },
    closeFacilityPopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
    },
    linkClick2(row, col) {
      this.linkClick(row, col, row, '2');
    },
    closeImprPopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.$emit('saveCallback', this.moc.sopMocId)
    },
    closeImprPopup2() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getImpList();
    },
    addImpr() {
      this.popupOptions.title = '개선요청';
      this.popupOptions.param = {
        requestContents: this.moc.mocTitle,
        relationTableKey: this.moc.sopMocId,
        ibmTaskTypeCd: 'ITT0000025',
        ibmTaskUnderTypeCd: 'ITTU000040', 
      };
      this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprDetail.vue'}`);
      this.popupOptions.width = '80%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeAddImprPopup;
    },
    addiimImpr() {
      this.popupOptions.title = '즉시조치';
      this.popupOptions.param = {
        requestContents: this.moc.mocTitle,
        relationTableKey: this.moc.sopMocId,
        ibmTaskTypeCd: 'ITT0000025',
        ibmTaskUnderTypeCd: 'ITTU000040', 
      };
      this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprImmDetail.vue'}`);
      this.popupOptions.width = '80%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeAddImprPopup;
    },
    closeAddImprPopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getImpList();
    }
  }
};
</script>
